import * as React from 'react'
import { Link } from 'gatsby'
import Logo from './images/logo'
import { gsap } from 'gsap'

export default function Header() {

  const menus = [
    { name: 'Utama', path: '/' },
    { name: 'Menu', path: '/menu/' },
    { name: 'Cerita kami', path: '/cerita-kami/' },
    { name: 'Hubungi', path: '/hubungi/' }
  ]

  function openMenuMobile() {
    gsap.to('#mobile-menu', { y: '100%', duration: .5, ease: 'power1.out'})
  }

  function closeMenuMobile() {
    gsap.to('#mobile-menu', { y: '-100%', duration: .5, ease: 'power1.out'})
  }

  function navigate() {
    setTimeout(() => {
      closeMenuMobile()
    }, 500);
  }

  const pathname = location.pathname
  console.log(pathname)

  return (
    <>
    <section id="mobile-menu">

      <img onClick={() => closeMenuMobile()} id="close" src="/close.svg"/>

      <nav>
        {
          menus.map((value, i) => {
            return (
              <div key={i} className="link-wrapper">
                <Link to={value.path}>
                  <a onClick={() => navigate()} className={pathname === value.path ? 'active' : ''}>{value.name}</a>
                </Link>
                {(() => {
                  if (pathname === value.path) {
                    return <div className="bullet"/>
                  }
                })()}
              </div>
            )
          })
        }
      </nav>

    </section>

    <header>
      <div id="logo">
        <Link to="/">
          <a>
            <Logo/>
          </a>
        </Link>
      </div>
      <nav>
        {
          menus.map((value, i) => {
            return (
              <div key={i} className="link-wrapper">
                <Link to={value.path}>
                  <a className={pathname === value.path ? 'active' : ''}>{value.name}</a>
                </Link>
                {(() => {
                  if (pathname === value.path) {
                    return <div className="bullet"/>
                  }
                })()}
              </div>
            )
          })
        }
      </nav>

      <div onClick={() => openMenuMobile()} id="menu-wrapper">
        <div/>
        <div/>
        <div/>
      </div>
      
    </header>
    </>
  )
}