import * as React from 'react'
import Header from './src/components/header'
import Footer from './src/components/footer'
import './src/styles/global.scss'

export const wrapPageElement = ({element}) => {
  return (
    <>
    <Header/>
      {element}
    <Footer/>
    </>
  )
}