import * as React from 'react'
import Logo from './images/logo'
import { Link } from 'gatsby'
import FB from './images/fb'

export default function Footer() {
  const menu = [
    { name: 'Utama', path: '/' },
    { name: 'Menu', path: '/menu' },
    { name: 'Cerita kami', path: '/cerita-kami' },
    { name: 'Hubungi', path: '/hubungi' },
  ]

  const pathname = location.pathname

  return (
    <footer>
      <section id="top-container">

        <div id="left-section">
          <Link to="/">
            <a>
              <Logo/>
            </a>
          </Link>
          <nav>
            {
              menu.map((val, i) => {
                return (
                  <Link key={i} to={val.path}>
                    <a className={pathname === val.path ? 'active' : ''}>{val.name}</a>
                  </Link>
                )
              })
            }
          </nav>
        </div>
        {/* #left-section */}

        <div id="right-section">
          <h4>Follow kami</h4>
          <a href="https://www.facebook.com/8272-Barakah-Corner-1441152745943915/" target="_blank"><FB/></a>
        </div>

      </section>

      <section id="bottom-container">
        <p>Hakcipta terpelihara © 2022 . 8272 Barakah Corner</p>
        <p>Designed by <a href="https://irsyadilham.com" target="_blank">Irsyad Ilham</a></p>
      </section>

    </footer>
  )
}