import * as React from 'react'

export default function Logo() {
  return (
    <svg className="logo" x="0px" y="0px" width="1080px" height="553.847px" viewBox="0 0 1080 553.847" enableBackground="new 0 0 1080 553.847">
    <g>
      <g>
        <path className="logo-path" d="M13.733,45.823c0-4.631,1.199-8.853,3.601-12.669c2.399-3.814,6.045-6.859,10.933-9.132
          c4.886-2.272,10.888-3.409,18.005-3.409c7.117,0,13.097,1.137,17.943,3.409c4.843,2.272,8.466,5.317,10.868,9.132
          c2.399,3.816,3.601,8.038,3.601,12.669c0,4.629-1.093,8.661-3.28,12.089c-2.186,3.43-5.124,6.132-8.81,8.103
          c4.629,2.231,8.187,5.296,10.675,9.197C79.755,79.113,81,83.68,81,88.909c0,6.089-1.544,11.34-4.63,15.755
          c-3.087,4.417-7.267,7.781-12.54,10.097c-5.272,2.315-11.125,3.473-17.557,3.473c-6.43,0-12.282-1.158-17.555-3.473
          c-5.274-2.315-9.454-5.68-12.541-10.097c-3.086-4.415-4.629-9.667-4.629-15.755c0-5.315,1.242-9.924,3.729-13.827
          c2.486-3.901,6.001-6.923,10.547-9.068C17.762,61.729,13.733,54.999,13.733,45.823z M36.499,78.812
          c-2.488,2.186-3.73,5.209-3.73,9.068c0,3.601,1.222,6.559,3.665,8.874c2.444,2.315,5.724,3.473,9.839,3.473
          c4.117,0,7.352-1.158,9.711-3.473c2.357-2.315,3.537-5.273,3.537-8.874c0-3.772-1.223-6.773-3.666-9.004
          c-2.443-2.229-5.639-3.344-9.583-3.344C42.242,75.533,38.984,76.626,36.499,78.812z M54.312,40.999
          c-2.016-1.929-4.694-2.894-8.04-2.894c-3.344,0-6.025,0.987-8.038,2.958c-2.016,1.974-3.022,4.717-3.022,8.231
          c0,3.345,1.029,5.981,3.086,7.91c2.058,1.929,4.715,2.894,7.974,2.894c3.259,0,5.917-0.985,7.975-2.958
          c2.058-1.972,3.087-4.63,3.087-7.974C57.334,45.651,56.326,42.928,54.312,40.999z"/>
        <path className="logo-path" d="M96.947,95.597c8.402-6.945,15.027-12.646,19.872-17.105c4.843-4.458,8.939-9.132,12.283-14.02s5.016-9.646,5.016-14.277
          c0-3.514-0.815-6.257-2.443-8.231c-1.63-1.972-4.074-2.958-7.331-2.958c-3.26,0-5.81,1.222-7.653,3.665
          c-1.845,2.444-2.765,5.897-2.765,10.354H92.703c0.17-7.287,1.737-13.376,4.694-18.263c2.958-4.888,6.859-8.49,11.705-10.804
          c4.843-2.315,10.226-3.473,16.142-3.473c10.202,0,17.898,2.616,23.086,7.845c5.187,5.231,7.781,12.048,7.781,20.45
          c0,9.176-3.131,17.685-9.389,25.531c-6.26,7.845-14.234,15.499-23.923,22.958h34.727v17.879H92.96V98.812
          C95.874,96.497,97.204,95.426,96.947,95.597z"/>
        <path className="logo-path" d="M227.108,39.006l-32.284,77.427h-22.507l32.668-74.984h-40V22.671h62.123V39.006z"/>
        <path className="logo-path" d="M239.196,95.597c8.402-6.945,15.027-12.646,19.872-17.105c4.843-4.458,8.939-9.132,12.283-14.02s5.016-9.646,5.016-14.277
          c0-3.514-0.815-6.257-2.443-8.231c-1.63-1.972-4.074-2.958-7.331-2.958c-3.259,0-5.81,1.222-7.653,3.665
          c-1.845,2.444-2.764,5.897-2.764,10.354h-21.223c0.171-7.287,1.737-13.376,4.694-18.263c2.959-4.888,6.859-8.49,11.705-10.804
          c4.843-2.315,10.226-3.473,16.142-3.473c10.202,0,17.898,2.616,23.087,7.845c5.186,5.231,7.781,12.048,7.781,20.45
          c0,9.176-3.131,17.685-9.389,25.531c-6.26,7.845-14.234,15.499-23.923,22.958h34.727v17.879h-64.566V98.812
          C238.123,96.497,239.454,95.426,239.196,95.597z"/>
      </g>
      <g>
        <path className="logo-path" d="M134.025,232.313c5.456,7.007,8.187,15.01,8.187,24.004c0,12.98-4.536,23.269-13.607,30.864
          c-9.071,7.599-21.72,11.393-37.942,11.393H18.316V143.264h69.913c15.781,0,28.132,3.616,37.058,10.841
          c8.921,7.229,13.385,17.036,13.385,29.425c0,9.147-2.4,16.742-7.19,22.788c-4.795,6.05-11.173,10.253-19.138,12.61
          C121.34,220.847,128.568,225.311,134.025,232.313z M56.149,206.097h24.779c6.195,0,10.951-1.362,14.27-4.094
          c3.318-2.727,4.978-6.747,4.978-12.058c0-5.309-1.659-9.364-4.978-12.167c-3.319-2.8-8.075-4.204-14.27-4.204H56.149V206.097z
          M98.739,263.73c3.463-2.876,5.199-7.042,5.199-12.5c0-5.455-1.809-9.735-5.421-12.832c-3.616-3.098-8.593-4.646-14.934-4.646
          H56.149v34.292h27.876C90.365,268.043,95.271,266.606,98.739,263.73z"/>
        <path className="logo-path" d="M163.782,203.22c4.792-9.586,11.318-16.959,19.581-22.124c8.258-5.161,17.478-7.744,27.655-7.744
          c8.701,0,16.333,1.77,22.897,5.31c6.562,3.54,11.616,8.186,15.155,13.938v-17.479h37.833v123.452H249.07v-17.477
          c-3.688,5.753-8.815,10.396-15.375,13.938c-6.565,3.538-14.198,5.309-22.899,5.309c-10.031,0-19.175-2.615-27.433-7.853
          c-8.263-5.236-14.789-12.684-19.581-22.347c-4.795-9.658-7.19-20.831-7.19-33.518C156.591,223.944,158.987,212.81,163.782,203.22z
          M241.216,214.503c-5.237-5.455-11.615-8.186-19.137-8.186c-7.523,0-13.905,2.693-19.138,8.076
          c-5.238,5.386-7.854,12.797-7.854,22.234c0,9.441,2.616,16.925,7.854,22.456c5.233,5.531,11.615,8.296,19.138,8.296
          c7.522,0,13.9-2.727,19.137-8.186c5.234-5.455,7.854-12.905,7.854-22.345C249.07,227.411,246.451,219.962,241.216,214.503z"/>
        <path className="logo-path" d="M368.76,179.658c6.637-3.906,14.011-5.863,22.124-5.863v40.045h-10.398c-9.441,0-16.521,2.029-21.24,6.084
          c-4.722,4.058-7.079,11.172-7.079,21.35v57.3h-37.833V175.122h37.833v20.576C356.591,188.916,362.122,183.568,368.76,179.658z"/>
        <path className="logo-path" d="M408.691,203.22c4.792-9.586,11.318-16.959,19.579-22.124c8.258-5.161,17.479-7.744,27.655-7.744
          c8.702,0,16.335,1.77,22.899,5.31c6.562,3.54,11.615,8.186,15.155,13.938v-17.479h37.832v123.452h-37.832v-17.477
          c-3.688,5.753-8.815,10.396-15.377,13.938c-6.563,3.538-14.197,5.309-22.896,5.309c-10.034,0-19.177-2.615-27.436-7.853
          c-8.261-5.236-14.788-12.684-19.579-22.347c-4.795-9.658-7.19-20.831-7.19-33.518C401.501,223.944,403.896,212.81,408.691,203.22z
          M486.127,214.503c-5.239-5.455-11.617-8.186-19.139-8.186s-13.902,2.693-19.137,8.076c-5.236,5.386-7.855,12.797-7.855,22.234
          c0,9.441,2.619,16.925,7.855,22.456c5.234,5.531,11.615,8.296,19.137,8.296s13.9-2.727,19.139-8.186
          c5.232-5.455,7.853-12.905,7.853-22.345C493.979,227.411,491.359,219.962,486.127,214.503z"/>
        <path className="logo-path" d="M634.685,298.574l-37.611-51.77v51.77h-37.83V134.857h37.83v90.488l37.39-50.223h46.686l-51.329,61.948l51.769,61.504
          H634.685z"/>
        <path className="logo-path" d="M695.637,203.22c4.793-9.586,11.318-16.959,19.581-22.124c8.258-5.161,17.477-7.744,27.655-7.744
          c8.702,0,16.332,1.77,22.896,5.31c6.563,3.54,11.617,8.186,15.154,13.938v-17.479h37.834v123.452h-37.834v-17.477
          c-3.687,5.753-8.812,10.396-15.376,13.938c-6.564,3.538-14.195,5.309-22.897,5.309c-10.033,0-19.175-2.615-27.433-7.853
          c-8.263-5.236-14.788-12.684-19.581-22.347c-4.792-9.658-7.19-20.831-7.19-33.518C688.446,223.944,690.845,212.81,695.637,203.22z
          M773.071,214.503c-5.236-5.455-11.616-8.186-19.137-8.186c-7.523,0-13.904,2.693-19.137,8.076
          c-5.236,5.386-7.856,12.797-7.856,22.234c0,9.441,2.62,16.925,7.856,22.456c5.232,5.531,11.613,8.296,19.137,8.296
          c7.521,0,13.9-2.727,19.137-8.186c5.232-5.455,7.853-12.905,7.853-22.345C780.924,227.411,778.304,219.962,773.071,214.503z"/>
        <path className="logo-path" d="M956.146,187.844c8.553,9.369,12.829,22.235,12.829,38.607v72.123h-37.608v-67.035c0-8.258-2.142-14.675-6.418-19.248
          c-4.276-4.571-10.029-6.858-17.255-6.858c-7.229,0-12.982,2.288-17.259,6.858c-4.276,4.573-6.414,10.99-6.414,19.248v67.035
          h-37.834V134.857h37.834v56.858c3.832-5.454,9.068-9.807,15.709-13.053c6.637-3.243,14.084-4.868,22.342-4.868
          C936.232,173.795,947.59,178.479,956.146,187.844z"/>
      </g>
      <g>
        <path className="logo-path" d="M351.117,356.456c4.438-8.29,10.577-14.689,18.411-19.194s16.777-6.758,26.833-6.758c13.188,0,24.06,3.265,32.614,9.794
          c8.551,6.53,14.069,15.409,16.551,26.638h-14.69c-1.831-7.703-5.778-13.742-11.852-18.117c-6.071-4.374-13.612-6.562-22.624-6.562
          c-7.183,0-13.646,1.633-19.391,4.896c-5.748,3.267-10.283,8.13-13.613,14.594c-3.329,6.463-4.995,14.395-4.995,23.797
          c0,9.401,1.666,17.367,4.995,23.897c3.33,6.529,7.865,11.427,13.613,14.689c5.745,3.265,12.208,4.897,19.391,4.897
          c9.012,0,16.553-2.186,22.624-6.562c6.073-4.375,10.021-10.479,11.852-18.315h14.69c-2.482,10.971-8.032,19.783-16.648,26.443
          c-8.619,6.659-19.459,9.988-32.517,9.988c-10.057,0-18.999-2.251-26.833-6.756c-7.834-4.507-13.974-10.901-18.411-19.196
          c-4.44-8.29-6.66-17.986-6.66-29.087C344.457,374.446,346.676,364.75,351.117,356.456z"/>
        <path className="logo-path" d="M490.477,433.826c-8.03-4.507-14.365-10.901-18.998-19.196c-4.637-8.29-6.954-17.986-6.954-29.087
          c0-11.097,2.349-20.793,7.05-29.087c4.701-8.29,11.098-14.689,19.196-19.194c8.094-4.505,17.169-6.758,27.226-6.758
          c10.053,0,19.161,2.253,27.322,6.758c8.16,4.505,14.56,10.904,19.196,19.194c4.633,8.294,6.955,17.99,6.955,29.087
          c0,10.968-2.352,20.635-7.053,28.989c-4.701,8.357-11.164,14.787-19.391,19.294c-8.226,4.505-17.368,6.756-27.423,6.756
          C507.548,440.582,498.509,438.331,490.477,433.826z M537.191,423.835c6.005-3.198,10.872-8.029,14.594-14.493
          c3.722-6.463,5.582-14.397,5.582-23.799c0-9.402-1.83-17.334-5.486-23.797c-3.654-6.464-8.488-11.295-14.493-14.496
          c-6.007-3.198-12.537-4.799-19.587-4.799c-7.052,0-13.582,1.601-19.587,4.799c-6.007,3.201-10.806,8.032-14.397,14.496
          c-3.591,6.463-5.386,14.395-5.386,23.797c0,9.401,1.795,17.336,5.386,23.799c3.591,6.464,8.356,11.295,14.299,14.493
          c5.94,3.201,12.438,4.8,19.488,4.8C524.657,428.635,531.185,427.036,537.191,423.835z"/>
        <path className="logo-path" d="M624.061,335.597c6.201-3.655,13.808-5.483,22.817-5.483v14.297h-3.722c-9.925,0-17.891,2.678-23.896,8.032
          c-6.007,5.355-9.009,14.297-9.009,26.833v59.74h-13.713V332.069h13.713v19.001C613.254,344.41,617.857,339.255,624.061,335.597z"
          />
        <path className="logo-path" d="M748.927,341.963c8.096,7.901,12.144,19.36,12.144,34.374v62.679h-13.514v-61.112c0-11.619-2.908-20.498-8.717-26.637
          c-5.812-6.138-13.744-9.206-23.799-9.206c-10.315,0-18.544,3.265-24.679,9.792c-6.14,6.532-9.206,16.131-9.206,28.793v58.37
          h-13.712V332.069h13.712v18.218c3.394-6.528,8.29-11.523,14.689-14.984c6.396-3.459,13.645-5.189,21.742-5.189
          C730.383,330.113,740.828,334.062,748.927,341.963z"/>
        <path className="logo-path" d="M887.798,390.833h-88.729c0.393,8.098,2.352,15.016,5.877,20.761c3.525,5.748,8.158,10.089,13.906,13.024
          c5.745,2.938,12.012,4.409,18.805,4.409c8.877,0,16.354-2.155,22.427-6.464c6.071-4.311,10.087-10.119,12.046-17.434h14.493
          c-2.612,10.449-8.194,18.971-16.747,25.562c-8.553,6.596-19.292,9.891-32.219,9.891c-10.058,0-19.066-2.251-27.03-6.756
          c-7.968-4.507-14.201-10.901-18.706-19.196c-4.504-8.29-6.758-17.986-6.758-29.087c0-11.097,2.22-20.827,6.66-29.186
          c4.437-8.354,10.642-14.753,18.607-19.194c7.964-4.438,17.041-6.659,27.227-6.659c10.185,0,19.096,2.221,26.735,6.659
          c7.639,4.441,13.516,10.416,17.628,17.923c4.114,7.509,6.171,15.833,6.171,24.973C888.19,384.76,888.059,388.354,887.798,390.833z
          M869.484,359.296c-3.33-5.612-7.869-9.856-13.613-12.73c-5.747-2.87-12.014-4.309-18.805-4.309
          c-10.185,0-18.869,3.265-26.05,9.793c-7.183,6.531-11.165,15.737-11.948,27.618h75.216
          C874.412,371.704,872.813,364.912,869.484,359.296z"/>
        <path className="logo-path" d="M940.78,335.597c6.201-3.655,13.809-5.483,22.817-5.483v14.297h-3.722c-9.925,0-17.89,2.678-23.895,8.032
          c-6.007,5.355-9.01,14.297-9.01,26.833v59.74H913.26V332.069h13.712v19.001C929.975,344.41,934.577,339.255,940.78,335.597z"/>
      </g>
      <g>
        <polygon className="logo-path" points="1071.385,543.493 342.696,543.493 342.696,506.347 1034.238,506.347 1034.238,134.942 1071.385,134.942 		"/>
      </g>
    </g>
    </svg>
  )
}